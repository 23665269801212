import { useEffect, useState } from 'react';
import { ChatBox } from '../component/chat';
import HeaderSlider from '../component/header-slider';
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, } from 'mdb-react-ui-kit';
import '../index.css';

import { FaThumbsUp, FaUserShield, FaCar, FaBus, FaStar, FaCarSide, FaHandshake, FaMoneyBill, FaMedal } from "react-icons/fa";
import { GiCarWheel } from "react-icons/gi";
import { FiCheckCircle } from "react-icons/fi";
import { BiSolidCarGarage, BiMessageSquareDetail } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

import { useResponsiveMode } from '../controller/utils';
import NavBar from '../component/navbar';
import PlacasMercosul from '../img/placasMercosul.png';
import InitialBkg from '../img/initial-bkg.png';
import BkgBike from '../img/bkg-hero1.png';
import BkgCar from '../img/bkg-hero2.png';
import BkgTruck from '../img/bkg-hero3.png';
import { FaCircleInfo } from 'react-icons/fa6';


function Index() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const goToElement = (elementId) => {
        const element = document.getElementById(elementId);
        element.scrollIntoView();
    };

    const slides = [
        {
            bg: InitialBkg, title: `Placas de 
Qualidade para 
Seu Veículo`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgBike, title: `Destaque-se
com Placas 
Personalizadas`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgCar, title: `Estilo e
Qualidade em Placas`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgTruck, title: `Durabilidade 
nas Placas`, cta_text: '', cta_url: '', cta_url_class: ''
        },
    ];


    const isMobile = useResponsiveMode();
    const [view, setView] = useState('home');
    const [activeIcon, setActiveIcon] = useState(null);

    const handleIconClick = (icon) => {
        setActiveIcon(icon === activeIcon ? null : icon);
    };

    return (
        <>
            {view === 'home' ?
                <div>
                    <NavBar />
                    <MDBContainer id='home' fluid className="headerContainer">
                        <HeaderSlider slides={slides} id='slider' />
                        {/* {!isMobile && (<div id='ia-assist' className='chatbox-container'> <ChatBox setView={setView} /> </div>)} */}
                    </MDBContainer>
                    <MDBContainer fluid className='bg-default'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={8} className='container-about-section'>
                                <p>A <span>Gol Placas ABC</span> é sua fonte confiável para placas veiculares
                                    personalizadas e de qualidade. Oferecemos uma variedade de opções para
                                    atender às suas necessidades, desde placas padrão até personalizadas.
                                    Com nossa dedicação à qualidade e serviço ao cliente,
                                    estamos aqui para ajudá-lo a destacar-se na estrada.</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer>
                        <div className='circles-holder'>
                            <MDBRow style={{ textAlign: 'center' }} className='justify-content-center align-items-start'>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'personalizacao' ? 'expanded' : ''}`} onClick={() => handleIconClick('personalizacao')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaCar size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>PERSONALIZAÇÃO</h4>
                                        <div className='content'>
                                            <p>Placas personalizadas para destacar sua marca. Gol Placas cria designs únicos para você. Personalize sua placa hoje!</p>
                                            <button onClick={() => window.open('https://wa.me/5511963117696?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Placas', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'identificacao' ? 'expanded' : ''}`} onClick={() => handleIconClick('identificacao')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaBus size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>IDENTIFICAÇÃO</h4>
                                        <div className='content'>
                                            <p>Identificação clara e eficaz com placas de alta qualidade. Gol Placas oferece soluções perfeitas. Identifique-se com Gol Placas!</p>
                                            <button onClick={() => window.open('https://wa.me/5511963117696?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Placas', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'durabilidade' ? 'expanded' : ''}`} onClick={() => handleIconClick('durabilidade')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaStar size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>DURABILIDADE</h4>
                                        <div className='content'>
                                            <p>Placas duráveis que resistem ao tempo. Gol Placas garante materiais de alta resistência. Invista em durabilidade hoje!</p>
                                            <button onClick={() => window.open('https://wa.me/5511963117696?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Placas', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'qualidade' ? 'expanded' : ''}`} onClick={() => handleIconClick('qualidade')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaCarSide size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>QUALIDADE</h4>
                                        <div className='content'>
                                            <p>Placas de qualidade superior para todas as necessidades. Gol Placas entrega excelência em cada produto. Garanta qualidade agora!</p>
                                            <button onClick={() => window.open('https://wa.me/5511963117696?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Placas', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'exclusividade' ? 'expanded' : ''}`} onClick={() => handleIconClick('exclusividade')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <BiSolidCarGarage size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>EXCLUSIVIDADE</h4>
                                        <div className='content'>
                                            <p>Placas exclusivas que refletem sua identidade. Gol Placas cria peças únicas para você. Tenha uma placa exclusiva hoje!</p>
                                            <button onClick={() => window.open('https://wa.me/5511963117696?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Placas', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'destaque' ? 'expanded' : ''}`} onClick={() => handleIconClick('destaque')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <GiCarWheel size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>DESTAQUE</h4>
                                        <div className='content'>
                                            <p>Destaque-se com placas atraentes e bem elaboradas. Gol Placas ajuda sua marca a brilhar. Faça sua placa se destacar!</p>
                                            <button onClick={() => window.open('https://wa.me/5511963117696?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Placas', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBContainer>
                    <MDBContainer id='vantagens' fluid className='vantagens-container'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={6}>
                                <h1 className='vantagens-title'>
                                    <span>Vantagens</span> da <span>Gol Placas ABC</span>
                                </h1>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='cardsHolder justify-content-center align-items-center'>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaMedal style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Variedade de Placas:
                                        </h3>
                                        <p>
                                            Oferecemos uma ampla variedade de placas, incluindo padrão, personalizadas,
                                            decorativas e muito mais, para atender a todas as preferências e necessidades
                                            dos nossos clientes.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FiCheckCircle style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Qualidade Garantida:
                                        </h3>
                                        <p>
                                            Todas as nossas placas são feitas com materiais duráveis e de alta qualidade,
                                            garantindo uma longa vida útil e resistência às condições adversas
                                            da estrada.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaThumbsUp style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Personalização Exclusiva:
                                        </h3>
                                        <p>
                                            Personalize sua placa com números, letras e design exclusivos
                                            para refletir sua personalidade e estilo.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaHandshake style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Atendimento Profissional:
                                        </h3>
                                        <p>
                                            Nossa equipe de especialistas está disponível para ajudá-lo a escolher a placa
                                            perfeita e fornecer orientação personalizada para atender às suas necessidades
                                            específicas.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaUserShield style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Entrega Rápida e Confiável:
                                        </h3>
                                        <p>
                                            Garantimos uma entrega rápida e confiável para que você possa
                                            desfrutar das suas novas placas o mais rápido possível,
                                            sem complicações.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='7' className='justify-content-center align-items-center py-4 d-flex'>
                                <button id='btn-cta-cotacao_vantagens' onClick={() => goToElement('contato')} className='contactBtn vantagens'>Solicite sua cotação</button>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer>
                        <MDBRow className='placas-mercosul justify-content-center align-items-center'>
                            <MDBCol md={6}>
                                <img src={PlacasMercosul} alt='Placas Mercosul' id='img_reclame_aqui' />
                            </MDBCol>
                            <MDBCol md={5}>
                                <h4>Transformando Identidade em Estilo</h4>
                                <p>Na Gol Placas, nossa missão vai além de simplesmente fabricar placas Mercosul.
                                    Somos os guardiões da identidade veicular, a ponte entre o carro e sua história.
                                    Desde nossa fundação, buscamos não apenas cumprir as regulamentações exigidas,
                                    mas também elevar o padrão estético e funcional das placas automotivas!</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer fluid className='contato-container' id='contato'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={4} className='text-center contact-col'>
                                <div className='contact-container'>
                                    <span className='contact-icon'><BiMessageSquareDetail /></span>
                                    <h4>Entre em contato agora mesmo!</h4>

                                    <a href='https://wa.me/5511963117696?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Placas%20ABC'>
                                        <button className="contactBtn whatsapp">
                                            <span>Entre em contato!</span>
                                            (11) 9 6311-7696
                                        </button>
                                    </a>
                                    {/* <button id='btn-cta-cotacao' onClick={() => goToElement('contato')} className='contactBtn'>Clique aqui e faça sua cotação!</button> */}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>

                    <Footer />
                    <a id='whatsappFloat' href="https://wa.me/5511963117696?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Placas%20ABC" rel="noreferrer" class="whatsapp-float hover-shadow" target="_blank">
                        <FaWhatsapp />
                    </a>
                    {/* {isMobile && (
                        <span id='upflow-float' onClick={() => setView('chat')} rel="noreferrer" class="upflow-float hover-shadow" target="_blank">
                            <FaCircleInfo size={'3rem'} color='#ff6900' />
                        </span>)
                    } */}
                </div>
                :
                <div>
                    <ChatBox setView={setView} />
                </div>
            }
        </>
    )
}

export default Index;